

.landingpage {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 24px 0px 0px;
  position: relative;
  width: 100%;
  text-align: -webkit-center;
  overflow-x: hidden;
  font-family: "Overpass" !important;
}

.landingpage b {
  font-weight: 900;
}

.landingpage .group.white { 
  padding-bottom: 0;
}

.landingpage .group {
  align-self: stretch;
  /* height: 1457px; */
  position: relative;
  width: 100%;
  padding-bottom: 275px;
}

.landingpage .overlap {
  /* height: 1457px; */
  position: relative;
  /* width: 1440px; */
  width: 100%;
}

.landingpage .frame {
  height: 1059px;
  left: 0;
  position: absolute;
  top: 398px;
  /* width: 1440px; */
  width: 100%;
}

.landingpage .frame-0 {
  position: absolute;
  width: 100%;
  top: -24px;
  background-image: url(/images/img/frame-145.svg);
  height: 880px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.landingpage .div {
  height: 1150px;
  position: relative;
}

.landingpage .subtract {
  /* height: 885px; */
  left: 0;
  position: absolute;
  top: 265px;
  /* width: 1440px; */
  width: 100%;
}

.landingpage .mask-group {
  height: 1150px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.landingpage .group-2 {
  /* height: 1067px; */
  /* left: 157px; */
  /* position: absolute; */
  /* top: 0; */
  /* width: 1133px; */
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
}

.landingpage .element-ordenes-linea {
  /* height: 597px;
  left: 98px;
  position: absolute;
  top: 470px;
  width: 936px; */
  position: relative;
  width: 80%;
}

.landingpage .group-3 {
  /* height: 429px; */
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  /* width: 1133px; */
  width: 100%;
}

.landingpage .frame-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 27px;
  /* left: 0; */
  /* position: absolute; */
  /* top: 141px; */
  /* width: 1133px; */
  width: 100%;
  padding: 60px 0;
}

.landingpage .frame-2.base-page {
  margin-top: 60px;
}

.landingpage .frame-2.special-page {
  padding: 110px 0 60px;
}

.landingpage .white .la-forma-m-s-r-pida {
  -webkit-text-fill-color: #fff;
  text-fill-color: #fff;
  background: none;
}

.landingpage .la-forma-m-s-r-pida {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 44px;
  font-weight: 600;
  /* height: 166px; */
  letter-spacing: 0;
  line-height: 58px;
  /* margin-top: -1.00px; */
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  margin: 0;
}

.landingpage .text-wrapper {
  color: #000000;
}

.landingpage .span {
  color: #000000;
  font-weight: 700;
}

.landingpage .text-wrapper-2 {
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
  /* width: 861px; */
  width: 90%;
}

.landingpage .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.landingpage .CTA-primary {
  /* align-items: center; */
  display: inline-flex;
  flex: 0 0 auto;
  /* flex-direction: column; */
  /* gap: 10px; */
  /* justify-content: center; */
  position: relative;
}

.landingpage .white .rectangle {
  background: #fff;
}

.landingpage .rectangle {
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 27.62%,
    rgb(215, 87, 212) 59.28%,
    rgb(246, 117, 84) 86.13%
  );
  border-radius: 6px;
  height: 42px;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  position: relative;
  /* width: 162px; */
  width: 200px;
}

.landingpage .white .crea-tu-cuenta {
  color: #3D77F7;
  font-weight: 600;
}

.landingpage .crea-tu-cuenta {
  color: white;
  font-weight: bold;
  /* font-family: var(--mobile-highlights-buttons-inputs-2-font-family); */
  font-size: var(--mobile-highlights-buttons-inputs-2-font-size);
  font-style: var(--mobile-highlights-buttons-inputs-2-font-style);
  font-weight: var(--mobile-highlights-buttons-inputs-2-font-weight);
  /* left: 23px; */
  letter-spacing: var(--mobile-highlights-buttons-inputs-2-letter-spacing);
  line-height: var(--mobile-highlights-buttons-inputs-2-line-height);
  position: absolute;
  text-align: center;
  /* top: 11px; */
  white-space: nowrap;
  /* border: 0px; */
  background-color: unset;
  /* height: 59px; */
  /* width: 243px; */
  height: auto;
  box-shadow: none;
  height: 42px;
  /* width: 162px; */
  width: 200px;
}

.landingpage .frame-4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* left: 11px; */
  /* position: absolute; */
  /* top: 0; */
  /* width: 1110px; */
  width: 100%;
  position: fixed; top: 0; left: 0; width: calc(100% - 15px); padding: 1rem; box-sizing: border-box; z-index: 1;
  background: #fff;
}

.landingpage .frame-4.background {
  background-image: url(/images/img/frame-145-3.png); background-size: 100%; background-color: transparent;
}

.landingpage .ico-logo-orbi-color {
  height: 34px;
  position: relative;
  cursor: pointer;
}

.landingpage .ico-logo-orbi-color-mobile {
  height: 40px;
  display: none;
  cursor: pointer;
}

.landingpage .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.landingpage .white .text-wrapper-3 {
  color: #fff;
}

.landingpage .text-wrapper-3.selected {
  text-decoration: underline;
}

.landingpage .text-wrapper-3 a {
  color: rgb(116, 119, 121);
}

.landingpage .white .text-wrapper-3 a {
  color: #fff;
}

.landingpage .text-wrapper-3 {
  color: rgb(116, 119, 121);
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.landingpage .white .crea-tu-cuenta-wrapper {
  background: #fff;
}

.landingpage .white .crea-tu-cuenta-wrapper button {
  color: #3D77F7;
}

.landingpage .crea-tu-cuenta-wrapper { 
  align-items: center;
  color: white;
  font-weight: bold;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 28%,
    rgb(215, 87, 212) 59%,
    rgb(246, 117, 84) 86%
  );
  border-radius: 6px;
  display: flex;
  gap: 10px;
  height: 42px;
  justify-content: center;
  margin-bottom: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  padding: 10px 41px;
  position: relative;
  width: 162px;
}

.landingpage .crea-tu-cuenta-2 {
  color: white;
  /* font-family: var(--mobile-highlights-buttons-inputs-2-font-family); */
  font-size: var(--mobile-highlights-buttons-inputs-2-font-size);
  font-style: var(--mobile-highlights-buttons-inputs-2-font-style);
  font-weight: var(--mobile-highlights-buttons-inputs-2-font-weight);
  letter-spacing: var(--mobile-highlights-buttons-inputs-2-letter-spacing);
  line-height: var(--mobile-highlights-buttons-inputs-2-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landingpage .frame-6 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* left: 208px;
  position: absolute;
  top: 1143px; */
  /* width: 1035px; */
  width: 100%;
  padding-top: 70px;
}

.landingpage .p {
  color: #ffffff;
  /* font-family: "Poppins", Helvetica; */
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  width: 577px;
}

.landingpage .group-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.landingpage .group-4 {
  /* position: relative; */
  height: 90px;
  width: 90px;
  border-radius: 50%;
}

.landingpage .tech-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 115px;
  justify-content: space-around;
  padding: 0px 10% 44px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.landingpage .tech {
  align-items: flex-start;
  display: flex;
  gap: 40px;
  position: relative;
  /* width: 1007px; */
  width: 100%;
}

.landingpage .title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  /* width: 567px; */
  width: 50%;
}

.landingpage .uno-para-todos-y {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  /* width: 517px; */
  width: 100%;
  text-align: left;
}

.landingpage .text-wrapper-4 {
  align-self: stretch;
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: left;
}

.landingpage .overlap-wrapper {
  /* height: 482.19px; */
  position: relative;
  /* width: 475.73px; */
  width: 80%;
}

.landingpage .overlap-2 {
  height: 482px;
  position: relative;
  width: 476px;
}

.landingpage .ellipse {
  border: 0.84px solid;
  border-color: #222228;
  border-radius: 174.58px;
  height: 349px;
  left: 59px;
  position: absolute;
  top: 74px;
  width: 349px;
}

.landingpage .ellipse-2 {
  border: 0.84px solid;
  border-color: #dd5dbf;
  border-radius: 119.35px;
  height: 239px;
  left: 115px;
  position: absolute;
  top: 129px;
  width: 239px;
}

.landingpage .ellipse-3 {
  border: 0.84px solid;
  border-color: #f27168;
  border-radius: 174.58px;
  height: 349px;
  left: 59px;
  position: absolute;
  top: 74px;
  width: 349px;
}

.landingpage .ellipse-4 {
  border: 0.84px solid;
  border-color: #3e7bf8;
  border-radius: 233.76px;
  height: 468px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 468px;
}

.landingpage .overlap-group-wrapper {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 151.94, 151.94, 0.3) 0%,
    rgba(128.41, 83.94, 255, 0.3) 100%
  );
  border-radius: 14.66px;
  height: 29px;
  left: 273px;
  position: absolute;
  top: 68px;
  width: 29px;
}

.landingpage .ellipse-wrapper {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 132, 49, 0.3) 0%,
    rgba(255, 193, 71, 0.3) 100%
  );
  border-radius: 14.66px;
  height: 29px;
}

.landingpage .ellipse-5 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255, 132, 49) 0%,
    rgb(255, 193, 71) 100%
  );
  border-radius: 8.3px;
  height: 17px;
  left: 6px;
  position: relative;
  top: 6px;
  width: 17px;
}

.landingpage .div-wrapper {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 132, 49, 0.3) 0%,
    rgba(255, 193, 71, 0.3) 100%
  );
  border-radius: 14.66px;
  height: 29px;
  left: 324px;
  position: absolute;
  top: 371px;
  width: 29px;
}

.landingpage .group-5 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 151.94, 151.94, 0.3) 0%,
    rgba(128.41, 83.94, 255, 0.3) 100%
  );
  border-radius: 14.66px;
  height: 29px;
  left: 51px;
  position: absolute;
  top: 280px;
  width: 29px;
}

.landingpage .ico-iso-orbi-color-1 {
  background-image: url(/images/img/orbi-icon-2.svg) !important;
  height: 117px !important;
  left: 175px !important;
  position: absolute !important;
  top: 190px !important;
  width: 117px !important;
}

.landingpage .icon-calendar-wrapper {
  background-color: #3d77f7;
  border-radius: 30.24px;
  height: 60px;
  left: 59px;
  position: absolute;
  top: 123px;
  width: 60px;
}

.landingpage .img-2 {
  /* height: 30px; */
  left: 15px;
  position: relative;
  top: 15px;
  /* width: 30px; */
  width: 100%;
}

.landingpage .icon-fcl-wrapper {
  background-color: #3d77f7;
  border-radius: 30.24px;
  height: 60px;
  left: 145px;
  position: absolute;
  top: 381px;
  width: 60px;
}

.landingpage .icon-fcl {
  height: 30px !important;
  left: 15px !important;
  position: absolute !important;
  top: 15px !important;
  width: 31px !important;
}

.landingpage .icon-reports-wrapper {
  background-color: #3d77f7;
  border-radius: 30.24px;
  height: 60px;
  left: 362px;
  position: absolute;
  top: 146px;
  width: 60px;
}

.landingpage .icon-nav-factory-wrapper {
  background-color: #3d77f7;
  border-radius: 30.24px;
  height: 60px;
  left: 113px;
  position: absolute;
  top: 0;
  width: 60px;
}

.landingpage .icon-nav-factory {
  height: 30px;
  left: 15px;
  position: absolute;
  top: 14px;
  width: 30px;
}

.landingpage .icon-historial-wrapper {
  background-color: #3d77f7;
  border-radius: 30.24px;
  height: 60px;
  left: 415px;
  position: absolute;
  top: 317px;
  width: 60px;
}

.landingpage .icon-historial-instance {
  height: 30px !important;
  left: 15px !important;
  top: 15px !important;
  width: 30px !important;
}

.landingpage .design-component-instance-node {
  background-image: url(/images/img/shape-2.svg) !important;
  height: 16px !important;
  left: 4px !important;
  top: 10px !important;
  width: 16px !important;
}

.landingpage .icon-historial-2 {
  height: 6px !important;
  left: 6px !important;
  top: 5px !important;
  width: 4px !important;
}

.landingpage .icon-historial-3 {
  left: 20px !important;
  top: 10px !important;
  width: 7px !important;
}

.landingpage .icon-historial-4 {
  left: 4px !important;
  top: 5px !important;
  width: 23px !important;
}

.landingpage .icon-historial-5 {
  left: 22px !important;
  top: 14px !important;
  width: 5px !important;
}

.landingpage .icon-historial-6 {
  left: 22px !important;
  top: 19px !important;
  width: 5px !important;
}

.landingpage .icon-historial-7 {
  left: 20px !important;
  top: 23px !important;
  width: 7px !important;
}

.landingpage .icon-location-wrapper {
  background-color: #3d77f7;
  border-radius: 30.24px;
  height: 60px;
  left: 22px;
  position: absolute;
  top: 368px;
  width: 60px;
}

.landingpage .icon-location {
  height: 30px;
  left: 16px;
  position: absolute;
  top: 15px;
  width: 30px;
}

.landingpage .row {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  position: relative;
}

.landingpage .frame-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 32px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.landingpage .frame-7 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.landingpage .text-wrapper-5 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 7.42%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
}

.landingpage .text-wrapper-6 {
  align-self: stretch;
  color: #9e9e9e;
  /* font-family: var(--text-regular-normal-font-family); */
  font-size: var(--text-regular-normal-font-size);
  font-style: var(--text-regular-normal-font-style);
  font-weight: var(--text-regular-normal-font-weight);
  letter-spacing: var(--text-regular-normal-letter-spacing);
  line-height: var(--text-regular-normal-line-height);
  position: relative;
}

.landingpage .frame-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: -16.50px;
  margin-right: -16.50px;
  position: relative;
  width: 369px;
}

.landingpage .frame-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: -16.50px;
  margin-right: -16.50px;
  position: relative;
  width: 369px;
}

.landingpage .frame-10 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  padding: 0px 0px 88px;
  position: relative;
  width: 100%;
}

.landingpage .frame-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.landingpage .text-wrapper-7 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 80px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  /* width: 490px; */
  width: 100%;
}

.landingpage .text-wrapper-8 {
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
  /* width: 720px; */
  width: 60%;
}

.landingpage .text-wrapper-8-1 {
  color: #9e9e9e;
  font-weight: 900;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}

.landingpage .frame-12 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  /* width: 1110px; */
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
}

.landingpage .frame-13 {
  align-items: center;
  align-self: stretch;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  padding: 32px;
  position: relative;
  transition: ease-in-out .5s;
}

.landingpage .frame-13:hover {
  transform: scale(1.05);
}

.landingpage .frame-14 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.landingpage .text-wrapper-9 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 7.42%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

.landingpage .frame-15 {
  align-items: flex-start;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  padding: 32px;
  position: relative;
  transition: ease-in-out .5s;
}

.landingpage .frame-15:hover {
  transform: scale(1.05);
}

.landingpage .frame-16 {
  align-self: stretch;
  background-color: #eff4ff;
  height: 695px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.landingpage .frame-17 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 51px;
  /* left: -120px; */
  position: relative;
  top: 80px;
  /* width: 1680px; */
  width: 100%;
}

.landingpage .text-wrapper-10 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 80px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

.landingpage .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.landingpage .group-6 {
  height: 337px;
  position: relative;
  width: 546px;
}

.landingpage .overlap-group-2 {
  background-color: #ffffff;
  border-radius: 20px;
  height: 337px;
  left: 0;
  position: absolute;
  top: 0;
  width: 540px;
}

.landingpage .text-wrapper-11 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  top: 37px;
  width: 450px;
}

.landingpage .overlap-group-3 {
  height: 55px;
  left: 119px;
  position: absolute;
  top: 263px;
  width: 120px;
}

.landingpage .text-wrapper-12 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 20px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.landingpage .text-wrapper-13 {
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 27px;
  white-space: nowrap;
}

.landingpage .group-7 {
  height: 19px;
  left: 358px;
  position: absolute;
  top: 275px;
  width: 144px;
}

.landingpage .line {
  height: 1px;
  left: 120px;
  object-fit: cover;
  position: absolute;
  top: 248px;
  width: 382px;
}

.landingpage .rectangle-2 {
  height: 50px;
  left: 6418px;
  object-fit: cover;
  position: absolute;
  top: -236px;
  width: 50px;
}

.landingpage .overlap-3 {
  background-color: #ffffff;
  border-radius: 20px;
  height: 337px;
  position: relative;
  width: 540px;
}

.landingpage .line-2 {
  height: 1px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 248px;
  width: 450px;
}

.landingpage .rectangle-3 {
  height: 50px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 266px;
  width: 50px;
}

.landingpage .group-8 {
  height: 337px;
  margin-right: -6.00px;
  position: relative;
  width: 546px;
}

.landingpage .group-9 {
  height: 19px;
  left: 358px;
  position: absolute;
  top: 275px;
  width: 62px;
}

.landingpage .line-3 {
  height: 1px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 248px;
  width: 368px;
}

.landingpage .group-10 {
  height: 16px;
  position: relative;
  width: 82px;
}

.landingpage .ellipse-6 {
  background: linear-gradient(
    -90deg,
    rgb(47, 79, 241) 0%,
    rgb(65, 132, 249) 100%
  );
  border-radius: 8px;
  height: 16px;
  left: 33px;
  position: absolute;
  top: 0;
  width: 16px;
}

.landingpage .ellipse-7 {
  background: linear-gradient(
    -90deg,
    rgba(47, 79, 241, 0.4) 0%,
    rgba(65, 132, 249, 0.4) 100%
  );
  border-radius: 8px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.landingpage .ellipse-8 {
  background: linear-gradient(
    -90deg,
    rgba(47, 79, 241, 0.4) 0%,
    rgba(65, 132, 249, 0.4) 100%
  );
  border-radius: 8px;
  height: 16px;
  left: 66px;
  position: absolute;
  top: 0;
  width: 16px;
}

.landingpage .frame-19 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 88px 0px 44px;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}

.landingpage .frame-20 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.landingpage .text-wrapper-14 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

.landingpage .text-wrapper-15 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 80px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  width: 490px;
}

.landingpage .frame-21 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  /* width: 1110px; */
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  align-items: baseline;

}

.landingpage .frame-22 {
  align-items: flex-start;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px 0px;
  position: relative;
  width: 350px;
}

.landingpage .element {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Roboto", Helvetica; */
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 80px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  margin: 0;
}

.landingpage .text-wrapper-16 {
  color: #000000;
  font-size: 52px;
}

.landingpage .text-wrapper-17 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-fill-color: transparent;
}

.landingpage .element-k {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Roboto", Helvetica; */
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 80px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  width: 267px;
}

.landingpage .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 95px; */
  padding: 44px 0px;
  position: relative;
  width: 100%;
}

.landingpage .frame-24 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.landingpage .frame-24-0 {
  margin-bottom: 95px;
}

.landingpage .frame-25 {
  align-items: flex-start;
  background-color: #eff4ff;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 50px 23px;
  position: relative;
  width: 25%;
  height: 185px;
  transition: ease-in-out .5s;
}

.landingpage .frame-25:hover {
  /* width: 30%;
  height: 195px; */
  transform: scale(1.1);
  transition: ease-in-out .5s;
}

.landingpage .frame-26 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  /* width: 304px; */
  width: 100%;
}

.landingpage .vector {
  height: 42.65px;
  position: relative;
  width: 48px;
}

.landingpage .frame-27 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.landingpage .text-wrapper-18 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 7.42%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

.landingpage .pag-pregunta-b {
  height: 48.01px;
  position: relative;
  width: 48px;
}

.landingpage .pag-pregunta-a {
  height: 43.18px;
  position: relative;
  width: 57.63px;
}

.landingpage .frame-28 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 44px 0px;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}

.landingpage .text-wrapper-19-0 {
  font-weight: 600;
  font-size: 24px;
}

.landingpage .text-wrapper-19 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: var(--heading-desktop-h3-font-family); */
  font-size: var(--heading-desktop-h3-font-size);
  font-style: var(--heading-desktop-h3-font-style);
  font-weight: var(--heading-desktop-h3-font-weight);
  letter-spacing: var(--heading-desktop-h3-letter-spacing);
  line-height: var(--heading-desktop-h3-line-height);
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 40px;
  font-weight: 600;
}

.landingpage .text-wrapper-19-1 { 
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.landingpage .text-wrapper-19-2 { 
  color: #9E9E9E;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 0 20%;
}

.landingpage .frame-29 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.landingpage .frame-30 {
  align-items: center;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px 23px;
  position: relative;
  /* width: 350px; */
  width: 25%;
  /* height: 280px; */
  transition: ease-in-out .5s;
  height: 330px;
  justify-content: center;
}

.landingpage .frame-30:hover { 
  transform: scale(1.1);
  transition: ease-in-out .5s;
}

.landingpage .frame-31 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  /* width: 303px; */
  width: 100%;
}

@property --myColor1 {
  syntax: '<color>';
  initial-value: #3D77F7;
  inherits: false;
}

@property --myColor2 {
  syntax: '<color>';
  initial-value: #3D77F7;
  inherits: false;
}

@property --myColor3 {
  syntax: '<color>';
  initial-value: #3D77F7;
  inherits: false;
}

@property --myColor4 {
  syntax: '<color>';
  initial-value: #3D77F7;
  inherits: false;
}

.landingpage .frame-30:hover .frame-31-1,
.landingpage .frame-36:hover .frame-31-1{
  /* background: linear-gradient(-45deg, var(--myColor1) 12%, var(--myColor2) 35%, var(--myColor3) 66%, var(--myColor4) 88%); */
  /* background: red; */
  /* transition: all .3s linear; */
  --myColor1: rgb(42, 67, 151);
  --myColor2: rgb(58, 114, 237);
  --myColor3: rgb(215, 87, 212);
  --myColor4: rgb(246, 117, 84);
}

.landingpage .frame-31-1 {
  position: relative;
  width: 104px;
  height: 104px;
  /* background: #3D77F7; */
  background: linear-gradient(-45deg, var(--myColor1) 12%, var(--myColor2) 35%, var(--myColor3) 66%, var(--myColor4) 88%);
  /* padding: 25px; */
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: all .3s linear; */
  transition: --myColor1 .5s, --myColor2 .5s, --myColor3 .5s, --myColor4 .5s;
}

.landingpage .frame-32 {
  /* height: 104px; */
  position: relative;
  /* width: 104px; */
  width: 60px;
  height: 60px;
}

.landingpage .frame-33 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.landingpage .text-wrapper-20 {
  color: #3d77f7;
  /* font-family: "Poppins", Helvetica; */
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  /* white-space: nowrap; */
  width: fit-content;
  white-space: break-spaces;
  margin: 0;
}

.landingpage .div-2 {
  align-self: stretch;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
}

.landingpage .text-wrapper-21 {
  color: #242e42;
}

.landingpage .text-wrapper-22 {
  color: #ff8a33;
  font-weight: 700;
}

.landingpage .text-wrapper-23 {
  color: #3d77f7;
  /* font-family: "Poppins", Helvetica; */
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  /* width: 274px; */
  width: auto;
}

.landingpage .frame-34 {
  align-items: flex-start;
  background-color: #eff1ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 50px 23px;
  position: relative;
  /* width: 350px; */
  width: 25%;
}

.landingpage .group-11 {
  /* height: 108px;
  margin-top: -1.00px;
  position: relative;
  width: 108px; */
  position: relative;
  /* width: 104px; */
  width: 54px;
  height: 54px;
}

.landingpage .frame-35 {
  /* height: 104px; */
  position: relative;
  /* width: 104px; */
  width: 66px;
  height: 66px;
}

.landingpage .text-wrapper-24 {
  align-self: stretch;
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.landingpage .frame-36 {
  align-items: center;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* height: 388px; */
  justify-content: space-between;
  padding: 50px 23px;
  position: relative;
  /* width: 730px; */
  width: calc(50% + 80px);
  height: 288px;
  transition: transform .5s ease-in-out;
  height: 330px;
}


.landingpage .frame-36:hover {
  transform: scale(1.05);
}

.landingpage .text-wrapper-25 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Roboto", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38.4px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  /* width: 618px; */
  width: 100%;
  margin: 0;
}

.landingpage .text-wrapper-26 {
  align-self: stretch;
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
  margin: 0;
}

.landingpage .frame-37 {
  align-items: center;
  color: white;
  font-weight: bold;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 27.62%,
    rgb(215, 87, 212) 59.28%,
    rgb(246, 117, 84) 86.13%
  );
  border-radius: 6px;
  display: flex;
  gap: 10px;
  height: 42px;
  justify-content: center;
  margin-bottom: -1.00px;
  padding: 10px 41px;
  position: relative;
  width: 162px;
  cursor: pointer;
}

.landingpage .frame-38 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  justify-content: center;
  padding: 44px 5%;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.landingpage .frame-39 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  position: relative;
  /* width: 1110px; */
  width: 100%;
}

.landingpage .text-wrapper-27 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  text-align: left;
}

.landingpage .frame-40 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 532px;
}

.landingpage .text-wrapper-28 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: var(--heading-desktop-h3-font-family); */
  font-size: var(--heading-desktop-h3-font-size);
  font-style: var(--heading-desktop-h3-font-style);
  font-weight: var(--heading-desktop-h3-font-weight);
  letter-spacing: var(--heading-desktop-h3-letter-spacing);
  line-height: var(--heading-desktop-h3-line-height);
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 40px;
  font-weight: 600;
  text-align: left;
}

.landingpage .frame-41-1 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.landingpage .frame-41-2 {
  /* width: 28%; */
  padding: 50px 23px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EFF4FF;
  width: 245px;
  flex-grow: 1;
  gap: 1em;
  height: calc(100% - 100px);
}

.frame-41-2-img {
  height: 50px;
}

.landingpage .frame-41-2-title {
  color: #3D77F7;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

.landingpage .frame-41-2-text {
  font-size: 16px;
  font-weight: 400;
  color: #092047;
  margin: 0;
}

.landingpage .frame-41 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 28px;
  position: relative;
  width: 100%;
}

.landingpage .frame-42 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 44px 52px;
  position: relative;
  /* width: 541px; */
  width: 50%;
  box-sizing: border-box;
  transition: ease-in-out .5s;
}

.landingpage .frame-42:hover {
  transform: scale(1.05);
}

.landingpage .frame-43 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  position: relative;
  /* width: 436px; */
  width: 100%;
}

.landingpage .group-12 {
  background-image: url(/images/img/icon-warehouse-1.svg);
  background-size: 100% 100%;
  height: 38px;
  position: relative;
  width: 38px;
}

.landingpage .frame-44 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.landingpage .frame-45 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.landingpage .text-wrapper-29 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 100%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  text-align: left;
}

.landingpage .si-tu-actividad {
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  /* width: 403px; */
  width: 100%;
  text-align: left;
}

.landingpage a:-webkit-any-link {
  text-decoration: none;
}

.landingpage .frame-46 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 36px;
  position: relative;
  /* cursor: pointer; */
}

.landingpage .CTA-primary-2 {
  align-items: center;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 100%
  );
  border-radius: 6px;
  display: flex;
  /* flex-direction: column; */
  /* gap: 10px; */
  justify-content: center;
  position: relative;
  width: 160px;
}

.landingpage .rectangle-4 {
  background: linear-gradient(
    -90deg,
    rgb(47, 79, 241) 0%,
    rgb(65, 132, 249) 100%
  );
  border-radius: 6px;
  height: 42px;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  position: relative;
  width: 162px;
  cursor: pointer;
}

.landingpage .crea-tu-cuenta-3 {
  color: white;
  /* font-family: var(--mobile-highlights-buttons-inputs-2-font-family); */
  font-size: var(--mobile-highlights-buttons-inputs-2-font-size);
  font-style: var(--mobile-highlights-buttons-inputs-2-font-style);
  font-weight: var(--mobile-highlights-buttons-inputs-2-font-weight);
  /* left: 41px; */
  letter-spacing: var(--mobile-highlights-buttons-inputs-2-letter-spacing);
  line-height: var(--mobile-highlights-buttons-inputs-2-line-height);
  /* position: absolute; */
  text-align: center;
  /* top: 11px; */
  white-space: nowrap;
  background: transparent;
  box-shadow: none;
}

.landingpage .crea-tu-cuenta-4 {
  color: #3d77f7;
  /* font-family: var(--mobile-highlights-buttons-inputs-2-font-family); */
  font-size: var(--mobile-highlights-buttons-inputs-2-font-size);
  font-style: var(--mobile-highlights-buttons-inputs-2-font-style);
  font-weight: var(--mobile-highlights-buttons-inputs-2-font-weight);
  letter-spacing: var(--mobile-highlights-buttons-inputs-2-letter-spacing);
  line-height: var(--mobile-highlights-buttons-inputs-2-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.landingpage .frame-47 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #eff4ff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 44px 52px;
  position: relative;
  /* width: 541px; */
  width: 50%;
  box-sizing: border-box;
  transition: ease-in-out .5s;
}

.landingpage .frame-47:hover {
  transform: scale(1.05);
}

.landingpage .frame-48 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  /* width: 436px; */
  width: 100%;
}

.landingpage .frame-49 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21px;
  position: relative;
  width: 100%;
}

.landingpage .group-13 {
  height: 30px;
  position: relative;
  width: 43px;
}

.landingpage .text-wrapper-30 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(215, 87, 212) 0%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
  text-align: left;
}

.landingpage .si-tu-actividad-2 {
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  /* width: 346px; */
  width: 100%;
  text-align: left;
}

.landingpage .CTA-primary-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 160px;
}

.landingpage .rectangle-5 {
  height: 40px;
  position: relative;
  width: 160px;
  cursor: pointer;
}

.landingpage .crea-tu-cuenta-5 {
  color: #ffffff;
  /* font-family: var(--mobile-highlights-buttons-inputs-2-font-family); */
  font-size: var(--mobile-highlights-buttons-inputs-2-font-size);
  font-style: var(--mobile-highlights-buttons-inputs-2-font-style);
  font-weight: var(--mobile-highlights-buttons-inputs-2-font-weight);
  /* left: 41px; */
  letter-spacing: var(--mobile-highlights-buttons-inputs-2-letter-spacing);
  line-height: var(--mobile-highlights-buttons-inputs-2-line-height);
  position: absolute;
  text-align: center;
  /* top: 11px; */
  white-space: nowrap;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  box-shadow: none;
}

.landingpage .crea-tu-cuenta-6 {
  color: #f67555;
  /* font-family: var(--mobile-highlights-buttons-inputs-2-font-family); */
  font-size: var(--mobile-highlights-buttons-inputs-2-font-size);
  font-style: var(--mobile-highlights-buttons-inputs-2-font-style);
  font-weight: var(--mobile-highlights-buttons-inputs-2-font-weight);
  letter-spacing: var(--mobile-highlights-buttons-inputs-2-letter-spacing);
  line-height: var(--mobile-highlights-buttons-inputs-2-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}

.landingpage .frame-50 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 52px;
  padding: 88px 0px;
  position: relative;
  width: 100%;    
  padding: 88px 20% 0;
  box-sizing: border-box;
}

.landingpage .frame-51 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 542px;
}

.landingpage .text-wrapper-31 {
  align-self: stretch;
  color: #9e9e9e;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
  text-align: center;
}

.landingpage .frame-52 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 52px;
  position: relative;
  /* width: 324px; */
  width: 414px;
  cursor: pointer;
}

.landingpage .rectangle-6 {
  align-self: stretch;
  background-color: #eff4ff;
  border-radius: 6px;
  height: 44px;
  position: relative;
  transform: rotate(180deg);
  width: 100%;
}

.landingpage .active {
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 100%
  );
  color: #fff !important;
}

.landingpage .frame-53 {
  align-items: center;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 12px 20px;
  position: absolute;
  top: 0;
  width: 162px;
}

.landingpage .frame-53.unique {
  left: 50%;
  transform: translate(-50%, -50%);

}

.landingpage .text-wrapper-32 {
  /* color: #ffffff; */
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landingpage .frame-54 {
  align-items: center;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  justify-content: center;
  /* left: 162px; */
  left: 50%;
  padding: 12px 27px;
  position: absolute;
  top: 0;
  width: 162px;
}

.landingpage .frame-54.unique {
  left: 50%;
  transform: translate(-50%, -50%);

}

.landingpage .text-wrapper-33 {
  /* color: #092047; */
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landingpage .group-14-1 {
  /* font-family: "Poppins", Helvetica; */
  display: flex;
  gap: 30px;
  width: 100%;
  justify-content: center;
}

.landingpage .group-14-container {
  padding: 1px 40px 20px;
  background: #EFF4FF;
  border-radius: 10px;
  /* margin: 0 30px; */
  text-align: left;
  /* width: 50%; */
  transition: transform .3s ease-in-out;
  margin: 0;        
  width: 70%;
  box-sizing: border-box;
  height: 100%;
}

.landingpage .group-14-container:hover {
  /* transform: scale(1.07); */
}

.landingpage .text-wrapper-141 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  background: linear-gradient(278deg, #2A4397 0%, #3A72ED 32.59%, #D757D4 65.59%, #F67554 98.59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landingpage .text-wrapper-142 {
  /* color: #000; */
  /* font-family: Poppins; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  background: linear-gradient(278deg, #2A4397 0%, #3A72ED 32.59%, #D757D4 65.59%, #F67554 98.59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landingpage .text-wrapper-143 {
  color: #242E42;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.landingpage .text-wrapper-144 {
  background: linear-gradient(278deg, #2A4397 0%, #3A72ED 32.59%, #D757D4 65.59%, #F67554 98.59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.landingpage .text-wrapper-145 {
  background: linear-gradient(278deg, #2A4397 0%, #3A72ED 32.59%, #D757D4 65.59%, #F67554 98.59%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: Poppins; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.landingpage .text-wrapper-146 {
  color: #242E42;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: left;
}

.landingpage .text-wrapper-147 {
  color: #FFF;
  /* font-family: Poppins; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.landingpage .text-wrapper-148 {
  color: #FFF;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.landingpage .text-wrapper-149 {
  color: #FFF;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.landingpage .group-14-item {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.landingpage .group-14-checkmark {
  height: 30px;
  width: 30px;
  margin-right: 8px;
}

.landingpage .group-14-2 {
  width: 100%;
}

.landingpage .group-14-2 .group-14-item {
  margin: 10px 0;
}

.landingpage .group-14-2 .group-14-checkmark {
  margin-right: 18px;
}

.landingpage .group-14-3 .group-14-item {
  margin: 12px 0;
}

.landingpage .group-14-3 .group-14-checkmark {
  margin-right: 24px;
}

.landingpage .group-14-3 {
  display: flex;
  /* height: 215px; */
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
  /* align-self: stretch; */
  color: #FFF;
  border-radius: 10px;
  background: linear-gradient(279deg, #2A4397 -22.24%, #3A72ED 24.6%, #D757D4 65.22%, #F67554 107.22%);
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.landingpage .frame-69-1 {
  width: 100%;
}

.landingpage .frame-69-container {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  gap: 24px;
  /* align-self: stretch; */
  border-radius: 10px;
  background: #EFF4FF;
  margin-bottom: 11px;
  justify-content: space-around;
  transition: transform .3s ease-in-out;
}

.landingpage .frame-69-container:hover {
  transform: scale(1.07);
}

.landingpage .frame-69-checkmark {
  width: 40px;
  height: 40px;
  margin-right: 24px;
}

.landingpage .frame-69-item-description {
  flex-grow: 1;
  text-align: left;
}

.landingpage .frame-69-extra-info {
  background: linear-gradient(279deg, #2A4397 -22.24%, #3A72ED 24.6%, #D757D4 65.22%, #F67554 107.22%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: Poppins; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}

.landingpage .text-wrapper-691 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  /* align-self: stretch; */
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
  text-align: left;
}

.landingpage .text-wrapper-692 {
  color: #242E42;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.landingpage .text-wrapper-693 {
  color: #242E42;
  /* font-family: Poppins; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
}

.landingpage .text-wrapper-694 {
  color: #242E42;
  /* font-family: Poppins; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.landingpage .text-wrapper-695 {
  /* font-family: Poppins; */
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 111.111% */
  margin: 0;
}

.landingpage .group-14 {
  height: 1269px;
  position: relative;
  width: 862px;
}

.landingpage .group-15 {
  height: 32px;
  left: 665px;
  position: absolute;
  top: 0;
  width: 155px;
}

.landingpage .text-wrapper-34 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-fill-color: transparent;
  top: 0;
  white-space: nowrap;
}

.landingpage .text-wrapper-35 {
  color: #092047;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.landingpage .text-wrapper-36 {
  color: #092047;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  left: 456px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.landingpage .group-16 {
  height: 399px;
  left: 0;
  position: absolute;
  top: 70px;
  width: 858px;
}

.landingpage .overlap-4 {
  height: 20px;
  left: 22px;
  position: absolute;
  top: 0;
  width: 528px;
}

.landingpage .text-wrapper-37 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 0;
  white-space: nowrap;
}

.landingpage .group-17 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 528px;
}

.landingpage .text-wrapper-38 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 700;
  left: 467px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-fill-color: transparent;
  top: 0;
  white-space: nowrap;
}

.landingpage .group-18 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 39px;
  width: 858px;
}

.landingpage .overlap-5 {
  border-radius: 10px;
  height: 50px;
  left: 624px;
  position: absolute;
  top: 0;
  width: 232px;
}

.landingpage .rectangle-7 {
  background-color: #eff4ff;
  border-radius: 10px;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 232px;
}

.landingpage .group-wrapper {
  align-items: center;
  background: linear-gradient(
    -90deg,
    rgb(47, 79, 241) 0%,
    rgb(65, 132, 249) 100%
  );
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  left: 102px;
  padding: 10px;
  position: absolute;
  top: 10px;
  width: 30px;
}

.landingpage .group-19 {
  height: 7px;
  position: relative;
  transform: rotate(45deg);
  width: 5px;
}

.landingpage .overlap-group-4 {
  height: 7px;
  position: relative;
}

.landingpage .rectangle-8 {
  background-color: #ffffff;
  border-radius: 4px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 2px;
}

.landingpage .rectangle-9 {
  background-color: #ffffff;
  border-radius: 4px;
  height: 5px;
  left: 1px;
  position: absolute;
  top: 4px;
  transform: rotate(90.00deg);
  width: 2px;
}

.landingpage .overlap-6 {
  border-radius: 10px;
  height: 50px;
  left: 420px;
  position: absolute;
  top: 0;
  width: 192px;
}

.landingpage .rectangle-10 {
  background-color: #eff4ff;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(242, 113, 105),
      rgb(216, 88, 209) 50%,
      rgb(57, 109, 227) 75%,
      rgb(46, 77, 169) 100%
    )
    1;
  border-radius: 10px;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 192px;
}

.landingpage .frame-55 {
  align-items: center;
  background: linear-gradient(
    -90deg,
    rgb(47, 79, 241) 0%,
    rgb(65, 132, 249) 100%
  );
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  left: 82px;
  padding: 10px;
  position: absolute;
  top: 10px;
  width: 30px;
}

.landingpage .overlap-7 {
  background-color: #eff4ff;
  border-radius: 10px;
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 408px;
}

.landingpage .text-wrapper-39 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.landingpage .group-20 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 101px;
  width: 858px;
}

.landingpage .text-wrapper-40 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.landingpage .group-21 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 163px;
  width: 858px;
}

.landingpage .text-wrapper-41 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 16px;
  white-space: nowrap;
  width: 94px;
}

.landingpage .group-22 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 225px;
  width: 858px;
}

.landingpage .group-23 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 287px;
  width: 858px;
}

.landingpage .group-24 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 349px;
  width: 858px;
}

.landingpage .frame-56 {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 50px;
  left: 92px;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 50px;
}

.landingpage .frame-57 {
  align-items: center;
  background: linear-gradient(
    -90deg,
    rgb(47, 79, 241) 0%,
    rgb(65, 132, 249) 100%
  );
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 30px;
}

.landingpage .rectangle-11 {
  background-color: #eff4ff;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(242, 113, 105),
      rgb(216, 88, 209) 50%,
      rgb(57, 109, 227) 75%,
      rgb(46, 77, 169) 100%
    )
    1;
  border-radius: 10px;
  height: 50px;
  left: 420px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 192px;
}

.landingpage .group-25 {
  height: 400px;
  left: 0;
  position: absolute;
  top: 507px;
  width: 858px;
}

.landingpage .text-wrapper-42 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 700;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.landingpage .group-26 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 858px;
}

.landingpage .text-wrapper-43 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.landingpage .group-27 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 102px;
  width: 860px;
}

.landingpage .viajes-ilimitados {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 12px;
  font-weight: 700;
  left: 142px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 6px;
}

.landingpage .group-28 {
  height: 30px;
  left: 61px;
  position: absolute;
  top: 10px;
  width: 73px;
}

.landingpage .group-29 {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.landingpage .group-30 {
  height: 30px;
}

.landingpage .group-31 {
  height: 33px;
  left: 42px;
  position: absolute;
  top: -1px;
  width: 33px;
}

.landingpage .group-32 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 164px;
  width: 858px;
}

.landingpage .text-wrapper-44 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
  width: 94px;
}

.landingpage .group-33 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 226px;
  width: 858px;
}

.landingpage .group-34 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 288px;
  width: 858px;
}

.landingpage .frame-58 {
  align-items: center;
  background: linear-gradient(
    -90deg,
    rgb(255, 132, 49) 0%,
    rgb(255, 193, 71) 100%
  );
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  left: 82px;
  padding: 10px;
  position: absolute;
  top: 10px;
  width: 30px;
}

.landingpage .group-35 {
  height: 50px;
  left: 0;
  position: absolute;
  top: 350px;
  width: 858px;
}

.landingpage .rectangle-12 {
  background-color: #eff4ff;
  border-radius: 10px;
  height: 50px;
  left: 420px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 192px;
}

.landingpage .text-wrapper-45 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 700;
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 15px;
  white-space: nowrap;
}

.landingpage .version-lite-wrapper {
  height: 40px;
  left: 22px;
  position: absolute;
  top: 946px;
  width: 269px;
}

.landingpage .version-lite {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
}

.landingpage .frame-59 {
  align-items: flex-start;
  background-color: #eff4ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 215px;
  left: 0;
  padding: 24px 22px;
  position: absolute;
  top: 1054px;
  width: 420px;
}

.landingpage .frame-60 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  margin-right: -9.00px;
  position: relative;
  width: 385px;
}

.landingpage .frame-61 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18px;
  position: relative;
}

.landingpage .group-36 {
  height: 30px;
  position: relative;
  width: 30px;
}

.landingpage .text-wrapper-46 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landingpage .frame-62 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 18px;
  position: relative;
  width: 100%;
}

.landingpage .frame-63 {
  align-items: center;
  background: linear-gradient(
    -90deg,
    rgb(255, 132, 49) 0%,
    rgb(255, 193, 71) 100%
  );
  border-radius: 2px;
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 30px;
}

.landingpage .text-wrapper-47 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: -44.00px;
  margin-top: -1.00px;
  position: relative;
  width: 381px;
}

.landingpage .group-37 {
  height: 32.57px;
  margin-left: -1.29px;
  position: relative;
  width: 32.57px;
}

.landingpage .text-wrapper-48 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: -15.00px;
  margin-top: -1.00px;
  position: relative;
  width: 352px;
}

.landingpage .frame-64 {
  align-items: flex-start;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 35.67%,
    rgb(215, 87, 212) 66.6%,
    rgb(246, 117, 84) 98.59%
  );
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 215px;
  left: 436px;
  padding: 24px;
  position: absolute;
  top: 1054px;
  width: 420px;
}

.landingpage .frame-65 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 28px;
  position: relative;
  width: 369px;
}

.landingpage .text-wrapper-49 {
  align-self: stretch;
  color: #ffffff;
  /* font-family: "Poppins", Helvetica; */
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
}

.landingpage .frame-66 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  position: relative;
  width: 330px;
}

.landingpage .frame-67 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
}

.landingpage .frame-68 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 276px;
}

.landingpage .text-wrapper-50 {
  align-self: stretch;
  color: #ffffff;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
}

.landingpage .text-wrapper-51 {
  align-self: stretch;
  color: #ffffff;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.landingpage .text {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  left: 409px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 37px;
  width: 215px;
}

.landingpage .frame-69 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 52px;
  height: 288px;
  position: relative;
  width: 856px;
}

.landingpage .text-wrapper-52 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  text-fill-color: transparent;
}

.landingpage .group-38 {
  height: 208px;
  margin-bottom: -4.00px;
  position: relative;
  width: 856px;
}

.landingpage .overlap-8 {
  background-color: #eff4ff;
  border-radius: 10px;
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 420px;
}

.landingpage .frame-70 {
  align-items: center;
  display: inline-flex;
  gap: 80px;
  left: 20px;
  position: relative;
  top: 18px;
}

.landingpage .group-39 {
  height: 42.57px;
  margin-left: -1.29px;
  position: relative;
  width: 42.57px;
}

.landingpage .frame-71 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 177px;
}

.landingpage .text-wrapper-53 {
  align-self: stretch;
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
}

.landingpage .text-wrapper-54 {
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landingpage .text-wrapper-55 {
  align-self: stretch;
  color: #242e42;
  /* font-family: "Poppins", Helvetica; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.landingpage .overlap-group-5 {
  background-color: #eff4ff;
  border-radius: 10px;
  height: 96px;
  left: 0;
  position: absolute;
  top: 112px;
  width: 420px;
}

.landingpage .frame-72 {
  align-items: center;
  display: inline-flex;
  gap: 70px;
  left: 20px;
  position: relative;
  top: 18px;
}

.landingpage .frame-73 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.landingpage .frame-74 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 69px;
}

.landingpage .text-wrapper-56 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 35.67%,
    rgb(215, 87, 212) 66.6%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
}

.landingpage .text-wrapper-57 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 35.67%,
    rgb(215, 87, 212) 66.6%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 40px;
  margin-right: -9.00px;
  position: relative;
  text-fill-color: transparent;
  width: 78px;
}

.landingpage .group-40 {
  height: 208px;
  left: 434px;
  position: absolute;
  top: 0;
  width: 422px;
}

.landingpage .overlap-9 {
  background-color: #eff4ff;
  border-radius: 10px;
  height: 96px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 420px;
}

.landingpage .text-wrapper-58 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 35.67%,
    rgb(215, 87, 212) 66.6%,
    rgb(246, 117, 84) 98.59%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 40px;
  position: relative;
  text-fill-color: transparent;
}

.landingpage .frame-75 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 88px 0px 0px;
  position: relative;
  width: 100%;
  /* padding: 88px 5%; */
  box-sizing: border-box;
}

.landingpage .group-41 {
  /* height: 1301px; */
  position: relative;
  /* width: 1440px; */
  width: 100%;
  height: 805px;
}

.landingpage .overlap-10 {
  /* height: 1301px; */
  position: relative;
  padding: 0 5%;
  height: 805px;
}

.landingpage .rectangle-13 {
  background-color: #eff4ff;
  height: 990px;
  left: 0;
  position: absolute;
  top: 311px;
  /* width: 1440px; */
  width: 100%;
  max-height: 100%;
}

.landingpage .group-76 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-top: 60px;
}

.landingpage .frame-76 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* height: 152px; */
  /* left: 165px; */
  /* position: absolute; */
  /* top: 572px; */
  /* width: 344px; */
  position: relative;
}

.landingpage .text-wrapper-59 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  align-self: stretch;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 11.78%,
    rgb(58, 114, 237) 35.38%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 88.27%
  );
  background-clip: text;
  color: transparent;
  /* font-family: "Poppins", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1.00px;
  position: relative;
  text-fill-color: transparent;
}

.landingpage .group-42 {
  /* height: 178px;
  left: 931px;
  position: absolute;
  top: 572px;
  width: 328px; */
  position: relative;
}

.landingpage .group-42-1 {
  display: flex;
  flex-direction: row;
}

.landingpage .rectangle-14 {
  background-color: #092047;
  border-radius: 10px;
  height: 68px;
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  width: 68px;
  margin: 31px;
}

.landingpage .rectangle-15 {
  background-color: #092047;
  border-radius: 10px;
  height: 68px;
  left: 260px;
  position: absolute;
  top: 0;
  width: 68px;
}

.landingpage .rectangle-16 {
  background-color: #092047;
  border-radius: 10px;
  height: 68px;
  left: 130px;
  position: absolute;
  top: 0;
  width: 68px;
}

.landingpage .rectangle-17 {
  background-color: #092047;
  border-radius: 10px;
  height: 68px;
  left: 0;
  position: absolute;
  top: 110px;
  width: 68px;
}

.landingpage .rectangle-18 {
  background-color: #092047;
  border-radius: 10px;
  height: 68px;
  left: 130px;
  position: absolute;
  top: 110px;
  width: 68px;
}

.landingpage .rectangle-19 {
  background-color: #092047;
  border-radius: 10px;
  height: 68px;
  left: 260px;
  position: absolute;
  top: 110px;
  width: 68px;
}

.landingpage .frame-77 {
  align-items: flex-start;
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 32.59%,
    rgb(215, 87, 212) 65.59%,
    rgb(246, 117, 84) 98.59%
  );
  border-radius: 9.72px;
  display: flex;
  flex-direction: column;
  gap: 9.72px;
  /* height: 425px; */
  /* left: 165px; */
  padding: 60.27px 94.29px;
  /* position: absolute; */
  /* top: 0; */
  /* width: 1110px; */
  width: 100%;
  box-sizing: border-box;
  /* margin: 0 5%;     */
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

.landingpage .frame-78 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 33.05px;
  position: relative;
  /* width: 500.62px; */
  width: 55%;
}

.landingpage .frame-79 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.53px;
  position: relative;
  width: 100%;
}

.landingpage .text-wrapper-60 {
  color: #ffffff;
  /* font-family: "Roboto", Helvetica; */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38.4px;
  margin-top: -0.97px;
  position: relative;
  /* width: 435px; */
  width: 100%;
  text-align: left;
}

.landingpage .adem-s-recibe {
  align-self: stretch;
  color: #ffffff;
  /* font-family: "Poppins", Helvetica; */
  font-size: 17.5px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 31.1px;
  position: relative;
  text-align: left;
}

.landingpage .frame-80 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.66px;
  position: relative;
}

.landingpage .CTA-primary-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 27.22px;
  position: relative;
}

.landingpage .CTA-primary-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 9.72px;
  justify-content: center;
  position: relative;
  width: 170px;
}

.landingpage .rectangle-20 {
  background-color: #ffffff;
  border-radius: 5.83px;
  box-shadow: 0px 0.97px 1.94px #08ecba48;
  height: 40.88px;
  margin-bottom: -1.00px;
  margin-left: -1.00px;
  margin-right: -1.00px;
  margin-top: -1.00px;
  position: relative;
  width: 170px;
  cursor: pointer;
}

.landingpage .crea-tu-cuenta-7 {
  color: #3d77f7;
  font-family: "Overpass", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  left: 17px;
  letter-spacing: 0;
  line-height: 17.5px;
  position: absolute;
  text-align: center;
  top: 11px;
  white-space: nowrap;
}

.landingpage .group-43 {
  /* height: 373px;
  left: 647px;
  position: absolute;
  top: 52px;
  width: 413px; */
  /* width: 40%;
  display: flex;
  justify-content: center;
  align-items: center; */
  position: absolute;
  height: 100%;
  top: 0;
  right: 5%;
  max-width: 40%;
}

.landingpage .overlap-11 {
  height: 373px;
  position: relative;
}

.landingpage .overlap-12 {
  height: 351px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 413px;
}

.landingpage .overlap-13 {
  height: 333px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 413px;
}

.landingpage .cloud-copy {
  /* height: 35px;
  left: 356px;
  position: absolute;
  top: 0;
  width: 57px; */
  height: 100%;
}

.landingpage .rectangle-21 {
  height: 160px;
  left: 19px;
  position: absolute;
  top: 18px;
  width: 354px;
}

.landingpage .group-44 {
  background: linear-gradient(
    -90deg,
    rgb(255, 255, 255) 0%,
    rgb(193.15, 229.89, 255) 100%
  );
  border-radius: 3.44px;
  box-shadow: 0px 1.94px 38.88px #2061f033;
  height: 69px;
  left: 78px;
  opacity: 0.9;
  position: absolute;
  top: 42px;
  width: 95px;
}

.landingpage .overlap-14 {
  height: 35px;
  left: 17px;
  position: absolute;
  top: 20px;
  width: 68px;
}

.landingpage .overlap-15 {
  height: 34px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 62px;
}

.landingpage .oval {
  background-color: #3872fa;
  border-radius: 8.43px;
  height: 17px;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 17px;
}

.landingpage .group-45 {
  height: 2px;
  left: 14px;
  position: absolute;
  top: 17px;
  transform: rotate(90deg);
  width: 31px;
}

.landingpage .oval-2 {
  background-color: #3872fa;
  border-radius: 2.2px;
  height: 4px;
  left: -1px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 4px;
}

.landingpage .oval-3 {
  background-color: #3872fa;
  border-radius: 2.2px;
  height: 4px;
  left: 6px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 4px;
}

.landingpage .oval-4 {
  background-color: #3872fa;
  border-radius: 2.2px;
  height: 4px;
  left: 13px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 4px;
}

.landingpage .oval-5 {
  background-color: #3872fa;
  border-radius: 2.2px;
  height: 4px;
  left: 21px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 4px;
}

.landingpage .oval-6 {
  background-color: #3872fa;
  border-radius: 2.2px;
  height: 4px;
  left: 28px;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: -1px;
  width: 4px;
}

.landingpage .group-46 {
  height: 23px;
  left: 42px;
  position: absolute;
  top: -3px;
  transform: rotate(90deg);
  width: 18px;
}

.landingpage .overlap-group-6 {
  height: 78px;
  left: -23px;
  position: relative;
  top: -25px;
  width: 76px;
}

.landingpage .combined-shape {
  height: 17px;
  left: 20px;
  mix-blend-mode: multiply;
  position: absolute;
  top: 28px;
  transform: rotate(-90deg);
  width: 23px;
}

.landingpage .combined-shape-2 {
  height: 76px;
  left: -1px;
  position: absolute;
  top: 1px;
  transform: rotate(-90deg);
  width: 78px;
}

.landingpage .group-47 {
  height: 12px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 23px;
}

.landingpage .path-6 {
  height: 9px;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 4px;
  width: 23px;
}

.landingpage .rectangle-22 {
  background-color: #3872fa;
  height: 4px;
  left: -1px;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: -1px;
  width: 12px;
}

.landingpage .combined-shape-3 {
  height: 11px;
  left: 45px;
  mix-blend-mode: multiply;
  position: absolute;
  top: 25px;
  width: 21px;
}

.landingpage .rectangle-23 {
  background-color: #3d77f7;
  height: 1px;
  left: 5px;
  opacity: 0.3;
  position: absolute;
  top: 12px;
  width: 86px;
}

.landingpage .path-7 {
  height: 4px;
  left: 87px;
  position: absolute;
  top: 4px;
  width: 4px;
}

.landingpage .group-48 {
  height: 100px;
  left: 256px;
  position: absolute;
  top: 91px;
  width: 131px;
}

.landingpage .overlap-16 {
  height: 101px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 133px;
}

.landingpage .rectangle-24 {
  background: linear-gradient(
    -90deg,
    rgb(255, 255, 255) 0%,
    rgb(126.55, 202.85, 255) 100%
  );
  border-radius: 9.72px;
  box-shadow: 0px 1.94px 38.88px #2061f0;
  height: 95px;
  left: 1px;
  position: absolute;
  top: 6px;
  width: 131px;
}

.landingpage .group-49 {
  height: 21px;
  left: 89px;
  position: absolute;
  top: 34px;
  width: 22px;
}

.landingpage .path-wrapper {
  background-image: url(/images/img/shape-3.svg);
  background-size: 100% 100%;
  height: 24px;
  left: -2px;
  position: relative;
  width: 24px;
}

.landingpage .path-8 {
  height: 9px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 9px;
}

.landingpage .rectangle-25 {
  background: linear-gradient(
    -90deg,
    rgb(255, 125.61, 52.33) 0%,
    rgb(205.17, 76.12, 255) 100%
  );
  border-radius: 9.72px 9.72px 0px 0px;
  box-shadow: 0px 5.83px 29.16px #20293d0f;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 133px;
}

.landingpage .path-9 {
  height: 6px;
  left: 119px;
  position: absolute;
  top: 6px;
  width: 6px;
}

.landingpage .group-50 {
  height: 4px;
  left: 74px;
  position: absolute;
  top: 88px;
  width: 43px;
}

.landingpage .oval-7 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: -1px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-8 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 9px;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-9 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 19px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-10 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 28px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-11 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 38px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .group-51 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 48px;
  transform: rotate(90deg);
  width: 43px;
}

.landingpage .oval-12 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 9px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-13 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 38px;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .combined-shape-wrapper {
  background-image: url(/images/img/combined-shape-5.svg);
  background-size: 100% 100%;
  height: 20px;
  left: 36px;
  position: absolute;
  top: 34px;
  width: 24px;
}

.landingpage .combined-shape-4 {
  height: 75px;
  left: -29px;
  position: absolute;
  top: -20px;
  width: 83px;
}

.landingpage .group-52 {
  height: 20px;
  left: 34px;
  position: absolute;
  top: 66px;
  width: 28px;
}

.landingpage .combined-shape-5 {
  height: 14px;
  left: 86px;
  mix-blend-mode: multiply;
  position: absolute;
  top: 62px;
  width: 29px;
}

.landingpage .path-10 {
  height: 175px;
  left: 11px;
  position: absolute;
  top: 157px;
  width: 375px;
}

.landingpage .window-copy {
  background: linear-gradient(
    -90deg,
    rgb(255, 255, 255) 0%,
    rgb(193.15, 229.89, 255) 100%
  );
  border-radius: 11.66px;
  box-shadow: 0px 1.94px 38.88px #2061f033;
  height: 104px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 138px;
  width: 141px;
}

.landingpage .oval-14 {
  background-color: #3872fa;
  border-radius: 12.18px;
  height: 24px;
  left: 93px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: 61px;
  width: 24px;
}

.landingpage .group-53 {
  height: 19px;
  left: 37px;
  position: absolute;
  top: 27px;
  width: 33px;
}

.landingpage .path-11 {
  height: 13px;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  top: 6px;
  width: 33px;
}

.landingpage .rectangle-26 {
  background-color: #3872fa;
  height: 5px;
  left: -1px;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: -1px;
  width: 17px;
}

.landingpage .rectangle-27 {
  background-color: #3d77f7;
  height: 1px;
  left: 8px;
  opacity: 0.3;
  position: absolute;
  top: 19px;
  width: 126px;
}

.landingpage .path-12 {
  height: 6px;
  left: 127px;
  position: absolute;
  top: 7px;
  width: 6px;
}

.landingpage .group-54 {
  height: 4px;
  left: -7px;
  position: absolute;
  top: 55px;
  transform: rotate(90deg);
  width: 46px;
}

.landingpage .oval-15 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 10px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-16 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 20px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-17 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 31px;
  mix-blend-mode: multiply;
  opacity: 0.1;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .oval-18 {
  background-color: #3872fa;
  border-radius: 3px;
  height: 6px;
  left: 41px;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  top: -1px;
  width: 6px;
}

.landingpage .img-wrapper {
  background-image: url(/images/img/combined-shape-7.svg);
  background-size: 100% 100%;
  height: 23px;
  left: 37px;
  position: absolute;
  top: 61px;
  width: 25px;
}

.landingpage .combined-shape-6 {
  height: 63px;
  left: -29px;
  position: absolute;
  top: -20px;
  width: 84px;
}

.landingpage .combined-shape-7 {
  height: 16px;
  left: 91px;
  mix-blend-mode: multiply;
  position: absolute;
  top: 30px;
  width: 30px;
}

.landingpage .group-55 {
  height: 201px;
  left: 158px;
  position: absolute;
  top: 115px;
  width: 82px;
}

.landingpage .cloud {
  height: 35px;
  left: 19px;
  position: absolute;
  top: 0;
  width: 57px;
}

.landingpage .cloud-2 {
  height: 38px;
  left: 196px;
  position: absolute;
  top: 0;
  width: 64px;
}

.landingpage .footer {
  background-image: url(/images/img/subtract-1.svg);
  /* background-size: 100% 100%; */
  height: 496px;
  /* left: 0; */
  /* position: absolute; */
  /* top: 805px; */
  /* width: 1440px; */
  background-size: 100%;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.landingpage .footer-2 {
  height: 55px;
  /* left: 635px; */
  position: absolute;
  top: 425px;
  width: 170px;
  left: calc(50% - 85px);
}

.landingpage .footer-3 {
  align-items: center;
  display: flex;
  gap: 65.38px;
  height: 119px;
  left: 424px;
  position: absolute;
  /* top: 1083px; */
  width: 590px;
  top: 265px;
  left: calc(50% - 295px);
}

.landingpage .frame-81 {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 172.6px;
}

.landingpage .text-wrapper-61 {
  align-self: stretch;
  color: #ffffff;
  /* font-family: "Poppins", Helvetica; */
  font-size: 18.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 36.6px;
  margin-top: -1.31px;
  position: relative;
  text-align: center;
}

.landingpage .icon {
  height: 120px;
  margin-bottom: -0.73px;
  margin-top: -0.73px;
  position: relative;
  width: 120px;
}

.landingpage .frame-82 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: -5.57px;
  position: relative;
  width: 172.6px;
}

.landingpage .img-2-s {
  display: none;
}

.landingpage .crea-tu-cuenta-2-btn {
  border: 0px;
  background-color: unset;
  height: 59px;
  width: 243px;
  box-shadow: none;
}

.dialog.login-detail-modal .overlay .content {
  transform: translateX(-50%);
  background-image: none;
  padding: 2rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-family: "Poppins", Helvetica; */
  position: relative;
  gap: 20px;
}

.login-detail-modal .modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #3D77F7;
}

.login-detail-modal .modal-message {
  font-size: 15px;
  line-height: 28px;
}

.login-detail-modal .modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.login-detail-modal .modal-icon {
  position: relative;
  width: 104px;
  height: 104px;
  background: #3D77F7;
  box-sizing: border-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-detail-modal .modal-icon img {
  position: relative;
  width: 60px;
  height: 60px;
}

.landingpage .frame-5-mobile {
  display: none;
}

.landingpage .frame-5-mobile-title {
  display: none;
}

.landingpage .ico-mobile-menu {
  height: 40px;
}

.landingpage .mobile-menu {
  position: absolute;
  right: -9px;
  bottom: -2px;;
  transform: translateY(100%);
  border-radius: 20px;
  overflow: hidden;    
  z-index: 1;
  box-shadow: 3px 3px 10px 0px #8888888f;
}

.landingpage .mobile-menu::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 2px; /* control the border thickness */
  background: linear-gradient(
    -90deg,
    rgb(42, 67, 151) 0%,
    rgb(58, 114, 237) 28%,
    rgb(215, 87, 212) 59%,
    rgb(246, 117, 84) 86%
  );
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
}

.landingpage .mobile-menu-item {
  white-space: nowrap;
  background: #fff;
  padding: 10px 10px;
  cursor: pointer;
  color: rgb(116, 119, 121);
  font-weight: 600;
  text-align: right;
}

.landingpage .mobile-menu-item a:-webkit-any-link {
  color: rgb(116, 119, 121);
}

.landingpage .carousel-container {
  width: 100%;
}

.landingpage .slider-wrapper .controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingpage .slider-wrapper .controls .btn-arrow {
  border: none;
  background: none;
  padding: 11px 20px;
  box-shadow: none;
}

.landingpage .slider-wrapper .controls .reverse-arrow {
  transform: rotateY(180deg);
}

.landingpage .slider-wrapper .controls .dot-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingpage .slider-wrapper .controls .dot-group .carousel__dot {
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 50%;
  margin: 0 4px;
  padding: 0;
  background-color: #c3c4ca;
}

.landingpage .slider-wrapper .controls .dot-group .carousel__dot--selected {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #6267a1;
  transition: background 0.4s ease;
}

@media only screen and (max-width: 1200px) {
  .landingpage .footer { height: 350px; }
  .landingpage .footer-3 { bottom: 70px; top: unset; }
  .landingpage .footer-2 { bottom: 0; top: unset; }
  .landingpage .frame-5 { gap: 20px; }
}

@media only screen and (max-width: 768px) {
  .landingpage .frame-2.base-page { margin-top: 30px; }
  .landingpage .frame-2.special-page { padding: 90px 0; }
  .landingpage .frame-30 { width: 35%; }
  .landingpage .frame {display: none;}
  .landingpage .frame-6 {gap: 0; grid-gap: 0; padding-bottom: 25px; padding-top: 100px; margin-top: -70px; background: linear-gradient(45deg, #2A4397 -22.24%, #3A72ED 24.6%, #D757D4 65.22%, #F67554 107.22%);}
  /* .landingpage .group-4 {width: 50%; box-sizing: border-box; padding: 15px 10%;} */
  .landingpage .group-container {display: block; box-sizing: border-box; padding: 0 10%;}
  .landingpage .p {width: 100%;}
  .landingpage .element-ordenes-linea {width: 100%;}
  .landingpage .group {padding-bottom: 50px;}
  .landingpage .img-2-s {display: block; width: 100%;}
  .landingpage .tech-wrapper {display: block; padding: 0px 5% 44px;}
  .landingpage .tech {display: block; margin-top: 20px;}
  .landingpage .title {width: 100%; margin-bottom: 40px;}
  .landingpage .uno-para-todos-y { margin: 0; font-size: 34px; line-height: 43px;}
  .landingpage .text-wrapper-4 { margin: 0;}
  .landingpage .overlap-wrapper { display: none; }
  .landingpage .la-forma-m-s-r-pida { font-size: 34px; line-height: 43px; }
  .landingpage .frame-7 { text-align: left; } 
  .landingpage .frame-8 { text-align: left; width: 100%; } 
  .landingpage .frame-9 { text-align: left; width: 100%; } 
  .landingpage .text-wrapper-5 { font-size: 28px; }
  .landingpage .frame-10 { padding: 0px 5% 44px; box-sizing: border-box; }
  .landingpage .text-wrapper-7 {font-size: 34px; line-height: 43px; text-align: left;} 
  .landingpage .text-wrapper-8 { width: 100%; text-align: left; }
  .landingpage .frame-12 { flex-direction: column; padding: 0; }
  .landingpage .text-wrapper-9 { font-size: 22px; text-align: left; margin: 0; }
  .landingpage .frame-19 { padding: 44px 5%; box-sizing: border-box; gap: 0; grid-gap: 0;}
  .landingpage .frame-20 { width: 100%;}
  .landingpage .frame-21 { flex-direction: column; padding: 0; }
  .landingpage .frame-22 { width: 100%; padding: 20px 0; gap: 0; grid-gap: 0; }
  .landingpage .text-wrapper-17 { margin: 0; }
  .landingpage .frame-23 { padding: 44px 5% 0; box-sizing: border-box; gap: 30px; grid-gap: 30px;  }
  .landingpage .frame-24 { flex-direction: column; }
  .landingpage .frame-25 { width: 100%; padding: 20px; box-sizing: border-box; height: auto; }
  .landingpage .text-wrapper-18 { margin: 0; }
  .landingpage .text-wrapper-19 { font-size: 34px; line-height: 43px; }
  .landingpage .frame-29 { flex-direction: column; }
  .landingpage .frame-30 { width: 100%; height: auto; box-sizing: border-box; padding: 20px; }
  .landingpage .frame-30:hover { transform: none; }
  .landingpage .text-wrapper-20 { width: 100%; white-space: normal; margin: 0;}
  .landingpage .text-wrapper-23 { width: 100%; }
  .landingpage .frame-36 { width: 100%; height: auto; box-sizing: border-box; padding: 20px; gap: 20px; grid-gap: 20px; }
  .landingpage .frame-36:hover { transform: none; }
  .landingpage .text-wrapper-25 { font-size: 24px; line-height: 32px; }
  .landingpage .frame-37 { width: 100%; height: 35px; box-sizing: border-box; }
  .landingpage .frame-40 { width: 100%; }
  .landingpage .text-wrapper-28 { margin: 0; }
  .landingpage .frame-41 { flex-direction: column; }
  .landingpage .frame-42 { width: 100%; padding: 20px; }
  .landingpage .frame-46 { flex-direction: column; width: 100%; }
  .landingpage .CTA-primary-2 { width: 100%; }
  .landingpage .rectangle-4 { width: 100%; }
  .landingpage .crea-tu-cuenta-3 { width: 100%; left: 0; }
  .landingpage .frame-47 { width: 100%; padding: 20px; }
  .landingpage .text-wrapper-29 { font-size: 30px; }
  .landingpage .text-wrapper-30 { font-size: 30px; }
  .landingpage .frame-48 { gap: 12px; grid-gap: 12px; }
  .landingpage .CTA-primary-3 { width: 100%; height: 40px; background: linear-gradient(45deg, #F67554, #D757D4); border-radius: 6px; }
  .landingpage .rectangle-5 { display: none; }
  .landingpage .crea-tu-cuenta-5 { width: 100%; }
  .landingpage .frame-50 { padding: 44px 5%; }
  .landingpage .frame-51 { width: 100%; margin-top: 50px; }
  .landingpage .frame-52 { width: 100%; }
  .landingpage .frame-53 { width: 50%; box-sizing: border-box; }
  .landingpage .frame-54 { width: 50%; box-sizing: border-box; left: 50%; }
  .landingpage .group-14-container { padding: 20px; margin: 0; box-sizing: border-box; }
  .landingpage .group-14-container:hover { transform: none; }
  .landingpage .group-14-2 .group-14-item { text-align: left; }
  .landingpage .group-14-3 { padding: 10px 20px; }
  .landingpage .frame-69-container { padding: 18px 10px; gap: 10px; grid-gap: 10px; position: relative; }
  .landingpage .frame-69-container.extra-info { padding-top: 30px; }
  .landingpage .frame-69-checkmark { margin-right: 5px; }
  .landingpage .frame-69-extra-info { position: absolute; display: flex; top: 10px; left: 10px; gap: 10px; grid-gap: 10px;}
  .landingpage .text-wrapper-695 { font-size: 18px; line-height: 20px; }
  .landingpage .frame-75 { padding-top: 44px; }
  .landingpage .group-41 { height: auto; }
  .landingpage .overlap-10 { height: auto; }
  .landingpage .rectangle-13 { display: none; }
  .landingpage .frame-77 { padding: 20px; }
  .landingpage .group-43 { display: none; }
  .landingpage .frame-78 { width: 100%; }
  .landingpage .text-wrapper-60 { margin: 0; font-size: 21px; line-height: 32px; }
  .landingpage .adem-s-recibe { margin: 0; font-size: 16px; line-height: 28px; }
  .landingpage .group-76 { flex-direction: column; gap: 30px; grid-gap: 30px;  }
  .landingpage .text-wrapper-59 { text-align: left; }
  .landingpage .rectangle-14 { margin: 10px; }
  .landingpage .footer { height: 200px; background: linear-gradient(90deg,rgb(42, 67, 151) 0%,rgb(58, 114, 237) 32.59%,rgb(215, 87, 212) 65.59%,rgb(246, 117, 84) 98.59%); }
  .landingpage .footer-2 { top: auto; bottom: 0; }
  .landingpage .footer-3 { top: auto; bottom: 70px; width: 100%; height: 80px; left: 0; justify-content: center; gap: 20px; grid-gap: 20px; }
  .landingpage .frame-81 { width: 90px; }
  .landingpage .text-wrapper-61 { font-size: 13px; line-height: 30px; }
  .landingpage .frame-82 { width: 90px; margin-right: 0; }
  .landingpage .icon { height: 70px; width: 70px; }
  .landingpage .text-wrapper-3 { display: none; }
  .landingpage .crea-tu-cuenta-wrapper { width: auto; padding: 10px; height: 20px; display: none; }
  .landingpage .crea-tu-cuenta-2-btn { width: auto; line-height: 20px; height: 20px; padding: 0; }
  .landingpage .frame-0 { background-image: url(/images/img/frame-145-3.png); }
  .landingpage .TL .frame-0 { height: 720px; }
  .landingpage .frame-5-mobile { display: block; position: relative;}
  .landingpage .white .frame-4 { background-image: url(/images/img/frame-145-3.png); background-size: 100%; }
  .landingpage .frame-5-mobile-title { display: block; color: #fff; font-weight: 600; }
}


@media only screen and (max-width: 450px) {
  .landingpage .ico-logo-orbi-color { display: none; }
  .landingpage .ico-logo-orbi-color-mobile { display: block; }

  .landingpage .group-14-container { width: 100%; }
  .landingpage .group-14-1 { flex-direction: column; }
  .landingpage .frame-5 { gap: 20px }
  .landingpage .crea-tu-cuenta-wrapper { display: none; }
  .landingpage .white .frame-4 { background-image: url(/images/img/frame-145-3.png); background-size: 100%; }
  
}